import React, {useEffect, useState} from 'react'
import {Box, Grid, Stack} from '@mui/material'
import {useLocation} from 'react-router-dom'
import VideoCards from '../components/video/VideoCards'
import {getSetting, setSetting} from '../common/utils'

import Select from 'react-select'
import SnackbarAlert from '../components/alert/SnackbarAlert'

import selectFolderTheme from '../common/reactSelectFolderTheme'
import selectSortTheme from '../common/reactSelectSortTheme'

import {SORT_OPTIONS} from '../common/constants'
import GroupService from "../services/GroupService";

function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Feed = ({authenticated, searchText, cardSize, listStyle}) => {
  const query = useQuery()
  const selection = query.get('selection')
  const [videos, setVideos] = useState([])
  const [search, setSearch] = useState(searchText)
  const [filteredVideos, setFilteredVideos] = useState([])
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState()
  const [selectedSort, setSelectedSort] = useState(getSetting('sortOption') || SORT_OPTIONS[0])

  const [alert, setAlert] = useState({open: false})

  const [prevCardSize, setPrevCardSize] = useState(cardSize)
  const [prevListStyle, setPrevListStyle] = useState(listStyle)

  if (searchText !== search) {
    setGroup(groups?.[0])
    setSearch(searchText)
  }
  if (cardSize !== prevCardSize) {
    setPrevCardSize(cardSize)
  }
  if (listStyle !== prevListStyle) {
    setPrevListStyle(listStyle)
  }

  const fetch = async () => {
    setLoading(true)

    try {
      const performers = await GroupService.getGroups("name")

      const groups = [{id: 0, label: "All Videos"}, ...performers]

      setGroups(groups)
      setGroup((selection && groups.find(value => value.id === selection)) || groups[0])
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        message: error.response?.message || 'Unknown Error',
      })

      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetch().then()
  }, [selectedSort])

  const handleGroupSelection = (selection) => {
    setGroup(selection)
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams('')

      if (selection.id !== 0) {
        searchParams.set('selection', selection.id)

        window.history.replaceState({selection: selection.id}, '', `/#/feed?${searchParams.toString()}`)
      } else {
        searchParams.delete('selection')

        window.history.replaceState({}, '', `/#/feed?${searchParams.toString()}`)
      }
    }
  }

  const handleSortSelection = (sortOption) => {
    setSetting('sortOption', sortOption)
    setSelectedSort(sortOption)
  }

  return (
    <>
      <SnackbarAlert severity={alert.type} open={alert.open} setOpen={(open) => setAlert({...alert, open})}>
        {alert.message}
      </SnackbarAlert>
      <Box sx={{height: '100%'}}>
        <Grid container item justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {groups && groups.length !== 0 && (
                <Grid item xs={11} sm={9} md={7} lg={5} sx={{mb: 3}}>
                  <Stack direction="row" spacing={1}>
                    <Box sx={{flexGrow: 1}}>
                      <Select
                        value={group}
                        options={groups}
                        onChange={handleGroupSelection}
                        styles={selectFolderTheme}
                        blurInputOnSelect
                        isSearchable={false}
                      />
                    </Box>
                    { group && group.id !== 0 &&
                      <Select
                        value={selectedSort}
                        options={SORT_OPTIONS}
                        onChange={handleSortSelection}
                        styles={selectSortTheme}
                        blurInputOnSelect
                        isSearchable={false}
                      />
                    }
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Box>
              {listStyle === 'card' && group && (
                <VideoCards
                  key={search}
                  group={group.id}
                  sort={selectedSort.value}
                  query={search}
                  size={cardSize}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Feed
