import Api from './Api'

const service = {
  async getGroups(sort) {
    const response = await Api().get('/api/groups', {
      params: {
        sort,
      },
    });

    return response.data?.["_embedded"]?.["groups"] || [];
  },
}

export default service
