import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'

const ComplianceStatement = () => {
  return (
    <Paper square sx={{ overflow: 'auto' }}>
      <Grid
        container
        direction="row"
        padding={4}
      >
        <Grid item>
          <Typography align="center" variant="h1">
            18 U.S. Code § 2257 Compliance Statement
          </Typography>
          <Typography>
            <p>Coomer Pub is not a producer (primary or secondary) of any and all of the content found on the website (coomer.pub). With respect to the records as per 18 U.S.C. 2257 for any and all content found on this site, please kindly direct your request to the site for which the content was produced.</p>

            <p>Coomer Pub abide by the following procedures to ensure compliance:</p>

            <ul>
              <li>Requiring all users to be 18 years of age to upload videos.</li>
              <li>When uploading, user must verify the content; assure he/she is 18 years of age; certify that he/she keeps records of the models in the content and that they are over 18 years of age.</li>
              <li>Moderating uploads to ensure compliance and expeditiously removing content if found to be in violation.</li>
            </ul>

            <p>For further assistance and/or information in finding the content's originating site, <a href={"/#/contact"}>contact us</a>.</p>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ComplianceStatement
