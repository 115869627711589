import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'

const DMCANotice = () => {
  return (
    <Paper square sx={{ overflow: 'auto' }}>
      <Grid
        container
        direction="row"
        padding={4}
      >
        <Grid item>
          <Typography align="center" variant="h1">
            DMCA Notice
          </Typography>
          <Typography>
            <p>Please allow up to 5 business days for a response to your DMCA request.</p>

            <p>This notice can be sent to <a href="mailto:legal@coomer.pub">legal@coomer.pub</a> with the following information:</p>

            <ul>
              <li>Identification of the copyrighted work claimed to have been infringed.</li>
              <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled.</li>
              <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address.</li>
              <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
              <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
            </ul>

            <p>For further assistance and/or information in finding the content's originating site, <a href={"/#/contact"}>contact us</a>.</p>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DMCANotice
