import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 3,
        py: 2,
        textAlign: 'center',
        backgroundColor: '#0A1929',
        color: '#fff',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: '#fff',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <a href="/#/contact" style={{ color: '#fff', textDecoration: 'none', marginRight: 15 }}>
          [Contact]
        </a>
        <a href="/#/2257" style={{ color: '#fff', textDecoration: 'none', marginRight: 15 }}>
          [2257]
        </a>
        <a href="/#/dmca" style={{ color: '#fff', textDecoration: 'none' }}>
          [DMCA]
        </a>
      </Typography>
    </Box>
  );
};

export default Footer;
