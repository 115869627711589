export const SORT_OPTIONS = [
  {
    value: 'name,desc',
    label: 'Default',
  },
  {
    value: 'duration,desc',
    label: 'Duration',
  },
]
