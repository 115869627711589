import React from 'react'
import {Box, IconButton, Paper, Typography} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {getPublicWatchUrl, getServedBy, getUrl, toHHMMSS, useDebounce} from '../../common/utils'
import VideoService from '../../services/VideoService'
import _ from 'lodash'
import UpdateDetailsModal from '../modal/UpdateDetailsModal'

const URL = getUrl()
const PURL = getPublicWatchUrl()

const CompactVideoCard = ({video, openVideoHandler, alertHandler, cardWidth, authenticated, deleted}) => {
  const [intVideo, setIntVideo] = React.useState(video)
  const [videoId, setVideoId] = React.useState(video.id)
  const [title, setTitle] = React.useState(video?.title)
  const [description, setDescription] = React.useState(video?.description)
  const [updatedTitle, setUpdatedTitle] = React.useState(null)
  const debouncedTitle = useDebounce(updatedTitle, 1500)
  const [privateView, setPrivateView] = React.useState(video?.private)

  const [detailsModalOpen, setDetailsModalOpen] = React.useState(false)

  const previousVideoRef = React.useRef()
  const previousVideo = previousVideoRef.current
  if (!_.isEqual(video, previousVideo) && !_.isEqual(video, intVideo)) {
    setIntVideo(video)
    setVideoId(video.id)
    setTitle(video?.title)
    setDescription(video?.description)
    setPrivateView(video?.private)
    setUpdatedTitle(null)
  }
  React.useEffect(() => {
    previousVideoRef.current = video
  })

  const handleOpenVideo = () => {
    openVideoHandler(video.id)
  }

  React.useEffect(() => {
    async function update() {
      try {
        await VideoService.updateTitle(video.id, debouncedTitle)
        setTitle(updatedTitle)
        alertHandler({
          type: 'success',
          message: 'Title Updated',
          open: true,
        })
      } catch (err) {
        alertHandler({
          type: 'error',
          message: 'An error occurred trying to update the title',
          open: true,
        })
      }
    }

    if (debouncedTitle && debouncedTitle !== title) {
      update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle])

  const handleMouseDown = (e) => {
    if (e.button === 1) {
      window.open(`${PURL}${video.id}`, '_blank')
    }
  }

  const handlePrivacyChange = async () => {
    try {
      await VideoService.updatePrivacy(video.id, !privateView)
      alertHandler({
        type: privateView ? 'info' : 'warning',
        message: privateView ? `Added to your public feed` : `Removed from your public feed`,
        open: true,
      })
      setPrivateView(!privateView)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDetailsModalClose = (update) => {
    setDetailsModalOpen(false)
    if (update) {
      if (update === 'delete') {
        deleted(videoId)
      } else {
        if (update.title !== title) setTitle(update.title)
        if (update.description !== description) setDescription(update.description)
      }
    }
  }

  const previewVideoHeight =
    video.details.width && video.details.height ? cardWidth * (video.details.height / video.details.width) : cardWidth / 1.77

  return (
    <>
      <UpdateDetailsModal
        open={detailsModalOpen}
        close={handleDetailsModalClose}
        videoId={video.id}
        currentTitle={title || ''}
        currentDescription={description || ''}
        alertHandler={alertHandler}
      />

      <Paper
        elevation={3}
        sx={{
          width: '100%',
          bgcolor: 'rgba(0, 0, 0, 0)',
          lineHeight: 0,
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            lineHeight: 0,
            bgcolor: 'rgba(0,0,0,0)',
            p: 0,
            '&:last-child': {p: 0},
          }}
        >
          <div
            style={{position: 'relative', cursor: 'pointer'}}
            onClick={handleOpenVideo}
            onMouseDown={handleMouseDown}
          >
            <img
              src={`${URL}${video.details.thumbnail}`}
              alt=""
              style={{
                width: cardWidth,
                minHeight: previewVideoHeight,
                borderRadius: '6px',
                background: 'repeating-linear-gradient(45deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px)',
              }}
            />

            <Box sx={{position: 'absolute', bottom: 3, left: 3}}>
              <CopyToClipboard text={`${PURL}${video.id}`}>
                <IconButton
                  sx={{
                    background: 'rgba(0, 0, 0, 0.4)',
                    '&:hover': {
                      background: '#2684FF88',
                    },
                  }}
                  aria-label="copy link"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    alertHandler({
                      type: 'info',
                      message: 'Link copied to clipboard',
                      open: true,
                    })
                  }}
                  onMouseDown={handleMouseDown}
                >
                  <LinkIcon/>
                </IconButton>
              </CopyToClipboard>
            </Box>
            <Box sx={{position: 'absolute', bottom: 14, right: 3}}>
              <Typography
                variant="div"
                color="white"
                sx={{
                  p: 0.5,
                  fontWeight: 700,
                  fontSize: 12,
                  fontFamily: 'monospace',
                  background: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '4px',
                }}
              >
                {video.details.duration}
              </Typography>
            </Box>
          </div>
        </Box>
      </Paper>
    </>
  )
}

export default CompactVideoCard
