import Api from './Api'

class AuthService {
  login(username, password) {
    return {
      data: true
    }
  }
  logout() {
    return Api().post('/api/logout')
  }
}

export default new AuthService()
