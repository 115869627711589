import React from 'react'
import { Route, HashRouter as Router, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import Watch from './views/Watch'
import NotFound from './views/NotFound'
import Feed from './views/Feed'
import { getSetting } from './common/utils'
import AuthWrapper from './components/utils/AuthWrapper'
import Navbar20 from './components/nav/Navbar20'
import {theme} from "./common";
import ContactUs from "./views/ContactUs";
import ComplianceStatement from "./views/ComplianceStatement";
import DMCANotice from "./views/DMCANotice";

const muitheme = createTheme(theme)

export default function App() {
  const drawerOpen = getSetting('drawerOpen') === undefined ? true : getSetting('drawerOpen')

  return (
    <Router>
      <ThemeProvider theme={muitheme}>
        <CssBaseline />
        <Routes>
          <Route
            path="/"
            element={
              <AuthWrapper redirect={'/feed'}>
                <Navbar20 page="/" collapsed={!drawerOpen} searchable>
                  <div></div>
                </Navbar20>
              </AuthWrapper>
            }
          />
          <Route
            path="/feed"
            element={
              <AuthWrapper>
                <Navbar20 page="/feed" collapsed={!drawerOpen} searchable cardSlider>
                  <Feed />
                </Navbar20>
              </AuthWrapper>
            }
          />
          <Route
            path="/w/:id"
            element={
              <Navbar20 collapsed={true} toolbar page="/w">
                <AuthWrapper>
                  <Watch />
                </AuthWrapper>
              </Navbar20>
            }
          />
          <Route
            path="/contact"
            element={
              <Navbar20 toolbar page="/contact">
                <ContactUs />
              </Navbar20>
            }
          />
          <Route
            path="/2257"
            element={
              <Navbar20 toolbar page="/2257">
                <ComplianceStatement />
              </Navbar20>
            }
          />
          <Route
            path="/dmca"
            element={
              <Navbar20 toolbar page="/dmca">
                <DMCANotice />
              </Navbar20>
            }
          />
          <Route
            path="*"
            element={
              <AuthWrapper>
                <NotFound />
              </AuthWrapper>
            }
          />
        </Routes>
      </ThemeProvider>
    </Router>
  )
}
