const theme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  direction: 'ltr',
  palette: {
    primary: {
      50: '#F2E5FF',
      100: '#D9B3FF',
      200: '#BF80FF',
      300: '#A64DFF',
      400: '#8C1AFF',
      500: '#7310E0',
      600: '#6600CC',
      700: '#5500AA',
      800: '#440088',
      900: '#330066',
      main: '#7310E0',
      light: '#A64DFF',
      dark: '#5500AA',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      50: '#E0F7F4',
      100: '#B3EFE9',
      200: '#80E7DD',
      300: '#4DDFD2',
      400: '#1AD7C7',
      500: '#00CFBB',
      600: '#00BFA5',
      700: '#00A78F',
      800: '#008F7A',
      900: '#007764',
      main: '#00BFA5',
      light: '#4DDFD2',
      dark: '#008F7A',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      default: '#1A1B2E',
      paper: '#252742',
    },
    common: {
      black: '#1C1C1C',
      white: '#ffffff',
    },
    text: {
      primary: '#E7EBF0',
      secondary: '#A0AAB4',
      disabled: 'rgba(255, 255, 255, 0.38)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    grey: {
      50: '#F4F5F6',
      100: '#E1E4E8',
      200: '#D1D6DB',
      300: '#A3ACB3',
      400: '#7D848A',
      500: '#525B60',
      600: '#40474D',
      700: '#2E353B',
      800: '#1C252B',
      900: '#12171C',
    },
    divider: 'rgba(194, 224, 255, 0.1)',
    error: {
      main: '#FF1744',
      light: '#FF616F',
      dark: '#D50000',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFD740',
      dark: '#FF8F00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#2979FF',
      light: '#5393FF',
      dark: '#0D47A1',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: '#00E676',
      light: '#66FFA6',
      dark: '#00B248',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    action: {
      active: '#ffffff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily:
      'Inter, "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h1: {
      fontSize: 'clamp(2.5rem, 1.2rem + 3.5vw, 4rem)',
      fontWeight: 800,
      lineHeight: 1.1,
    },
    h2: {
      fontSize: 'clamp(1.5rem, 1rem + 2vw, 2.25rem)',
      fontWeight: 800,
      lineHeight: 1.2,
      color: '#E7EBF0',
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#00BFA5', // Updated to new secondary color
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#A0AAB4',
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '0.875rem',
    },
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0,0,0,0.2)',
    '0px 3px 6px rgba(0,0,0,0.2)',
    '0px 4px 8px rgba(0,0,0,0.2)',
    '0px 5px 10px rgba(0,0,0,0.2)',
    '0px 6px 12px rgba(0,0,0,0.2)',
    '0px 7px 14px rgba(0,0,0,0.2)',
    '0px 8px 16px rgba(0,0,0,0.2)',
    '0px 9px 18px rgba(0,0,0,0.2)',
    '0px 10px 20px rgba(0,0,0,0.2)',
    '0px 11px 22px rgba(0,0,0,0.2)',
    '0px 12px 24px rgba(0,0,0,0.2)',
    '0px 13px 26px rgba(0,0,0,0.2)',
    '0px 14px 28px rgba(0,0,0,0.2)',
    '0px 15px 30px rgba(0,0,0,0.2)',
    '0px 16px 32px rgba(0,0,0,0.2)',
    '0px 17px 34px rgba(0,0,0,0.2)',
    '0px 18px 36px rgba(0,0,0,0.2)',
    '0px 19px 38px rgba(0,0,0,0.2)',
    '0px 20px 40px rgba(0,0,0,0.2)',
    '0px 21px 42px rgba(0,0,0,0.2)',
  ],
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeLarge: {
          padding: '0.875rem 1rem',
          fontSize: '1rem',
          lineHeight: 1.3125,
          letterSpacing: 0,
          fontFamily:
            '"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
          fontWeight: 700,
        },
        sizeSmall: {
          padding: '4px 8px',
          marginLeft: '-8px',
        },
        containedPrimary: {
          backgroundColor: '#7310E0',
          color: '#fff',
        },
      },
      variants: [
        {
          props: {
            variant: 'code',
          },
          style: {
            color: '#B2BAC2',
            border: '1px solid',
            borderColor: '#7E57C2',
            backgroundColor: '#512DA8',
            fontFamily:
              'Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace',
            fontWeight: 400,
            fontSize: '0.8125rem',
            lineHeight: 1.5,
            letterSpacing: 0,
            WebkitFontSmoothing: 'subpixel-antialiased',
            '&:hover, &.Mui-focusVisible': {
              borderColor: '#AB47BC',
              backgroundColor: '#5E35B1',
              '& .MuiButton-endIcon': {
                color: '#BA68C8',
              },
            },
            '& .MuiButton-startIcon': {
              color: '#B2BAC2',
            },
            '& .MuiButton-endIcon': {
              display: 'inline-block',
              position: 'absolute',
              right: 0,
              marginRight: 10,
              color: '#B2BAC2',
            },
          },
        },
        {
          props: {
            variant: 'link',
          },
          style: {
            fontSize: '0.875rem',
            fontWeight: 700,
            color: '#00BFA5', // Updated to new secondary color
            mb: 1,
            '& svg': {
              ml: -0.5,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            color: 'primary',
          },
          style: {
            height: 34,
            width: 34,
            border: '1px solid #7310E0',
            borderRadius: 10,
            color: '#00BFA5', // Updated to new secondary color
            '&:hover': {
              borderColor: '#5E35B1',
              background: 'rgba(19, 47, 76, 0.4)',
            },
          },
        },
      ],
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          mt: 0.5,
          minWidth: 160,
          elevation: 0,
          color: '#B2BAC2',
          backgroundColor: '#311B92',
          border: '1px solid #7310E0',
          '& .MuiMenuItem-root': {
            fontSize: '0.875rem',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'rgba(19, 47, 76, 0.4)',
            },
            '&:focus': {
              backgroundColor: 'rgba(19, 47, 76, 0.4)',
            },
            '&.Mui-selected': {
              fontWeight: 500,
              color: '#00BFA5', // Updated to new secondary color
              backgroundColor: '#512DA8',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#0A1929',
          '&[href]': {
            textDecorationLine: 'none',
          },
        },
        outlined: {
          display: 'block',
          borderColor: '#7310E0',
          backgroundColor: '#512DA8',
          'a&, button&': {
            '&:hover': {
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width:900px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px)': {
        '@media (orientation: landscape)': {
          minHeight: 48,
        },
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
};
export default theme;
