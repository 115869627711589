import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'

const ContactUs = () => {
  return (
    <Paper square sx={{ overflow: 'auto' }}>
      <Grid
        container
        direction="row"
        padding={4}
      >
        <Grid item>
          <Typography align="center" variant="h1">
            Contact Us
          </Typography>
          <Typography>
            <span>Questions: </span> <a href="mailto:contact@coomer.pub">contact@coomer.pub</a>
          </Typography>
          <Typography>
            <span>Legal: </span> <a href="mailto:legal@coomer.pub">legal@coomer.pub</a>
          </Typography>
          <Typography>
            <span>Advertising: </span> <a href="mailto:contact@coomer.pub">ads@coomer.pub</a>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContactUs
